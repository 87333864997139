import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Another MacOS and another struggle through installing nokogiri. Most places online recommend using `}<inlineCode parentName="p">{`--with-xml2-include`}</inlineCode>{` and `}<inlineCode parentName="p">{`--use-system-libraries`}</inlineCode>{` during `}<inlineCode parentName="p">{`gem install`}</inlineCode>{`, but if you're installing from a Gemfile using `}<inlineCode parentName="p">{`bundle install`}</inlineCode>{`, it can sometimes be a little trickier.`}</p>
    <p>{`To enable passing args like this to a gem installed from a Gemfile, we can use `}<a parentName="p" {...{
        "href": "https://bundler.io/v1.16/bundle_config.html"
      }}>{`Bundler Config`}</a>{`. The magic command to run before `}<inlineCode parentName="p">{`bundle install`}</inlineCode>{` on Mojave is:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bundle config build.nokogiri --with-xml2-include=/Applications/Xcode.app/Contents/Developer/Platforms/MacOSX.platform/Developer/SDKs/MacOSX.sdk/usr/include/libxml2   --use-system-libraries`}</span></code></pre>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      